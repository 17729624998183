import Quiz from "components/Quiz/Quiz";
import { useState } from "react";
import QuizBrief from "components/Quiz/QuizBrief";
import PasswordProtected from "components/Quiz/PasswordProtected";
import QuizResult from "components/Quiz/QuizResult";

export default function QuizView(props: any) {
    const [quizStage, setQuizStage] = useState("brief" as "brief" | "quiz" | "result");
    const [isSuccess, setSuccess] = useState(false);
    const { id } = props.match.params;

    const onQuizSubmit = (isSucc: boolean) => {
        if(isSucc){
            setSuccess(true);
            setQuizStage("result");
        }
        else{
            setQuizStage("result");
        }
    }

    const { questions, title, meta } = require(`../components/Quiz/questions/assignment${id}`)

    return (
        <div className="QuizView w-100 overflow-hidden">
            <PasswordProtected type={"quiz"} id={id} headerText="Enter your Password:" enableEmail={true} onSubmit={() => { }}>
                {quizStage === "quiz" ? <Quiz onSubmit={onQuizSubmit} questions={questions} headerText={title} headerClassName={"ch5"} /> : null}
                {quizStage === "brief" ? <QuizBrief assignmentNumber={id} onNext={() => { setQuizStage("quiz"); }} /> : null}
                {quizStage === "result" ? <QuizResult failureCallback={() => { setQuizStage("quiz"); }} id={id} title={title} meta={meta} isSuccess={isSuccess} /> : null}
            </PasswordProtected>
        </div>
    )
}