
import HomeView from '../views/HomeView';
import AboutView from '../views/AboutView';
import VisitView from '../views/VisitView';
import ExhibitView from '../views/ExhibitView';
import StaffView from '../views/StaffView';
import VolunteersView from '../views/VolunteersView';
import ContactView from '../views/ContactView';
import OralHistoriesView from '../views/OralHistoriesView';
import LiveQuizView from '../views/LiveQuizView';
import QuizView from '../views/QuizView';
import ContentView from 'views/ContentView';

export const defaultRoutes = [
  { path: '/', exact: true, name: 'Home', component: HomeView },
  { path: '/about', exact: true, name: 'About', component: AboutView },
  { path: '/visit', exact: true, name: 'Visit', component: VisitView },
  { path: '/exhibit', exact: true, name: 'Exhibit', component: ExhibitView },
  { path: '/staff', exact: true, name: 'Staff', component: StaffView },
  { path: '/volunteers', exact: true, name: 'Volunteers', component: VolunteersView },
  { path: '/contact', exact: true, name: 'Contact', component: ContactView },
];

export const hiddenRoutes = [
  { path: '/oral-histories', exact: true, name: 'OralHistories', component: OralHistoriesView },
  { path: '/live-quiz', exact: true, name: 'LiveQuiz', component: LiveQuizView },
  { path: '/quiz/:id', exact: true, name: 'Quiz', component: QuizView },
  { path: '/content/:id', exact: true, name: 'Content', component: ContentView },
]