import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout/Layout';
import './styles/app.scss';
import 'bootstrap/dist/js/bootstrap.js';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

ReactDOM.render(<BrowserRouter basename={baseUrl}>
  <Layout />
</BrowserRouter>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
