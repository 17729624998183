import PasswordProtected from "components/Quiz/PasswordProtected";

export default function ContentView(props: any) {
    const { id } = props.match.params;

    let { Content } = require(`components/Content/Content${id}`)

    return (
        <div className="ContentView w-100 overflow-hidden">
            <PasswordProtected type={"content"} id={id} headerText="ENTER YOUR PASSWORD TO ACCESS THE EXCLUSIVE CONTENT:" enableEmail={false} onSubmit={() => { }}>
                <Content />
            </PasswordProtected>
        </div>
    )
}